import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Header from 'components/header';
import Footer from 'components/footer';
import Layout from 'components/layout';
import Box from '@mui/material/Box';
import { ResolveSlices } from 'components/slices';
//import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'helpers/linkResolver';

function Page(props) {
  const { data } = props;
  const doc = data.prismicPage.data;
  if (!doc) return null;
  const showMultiBannerImages =
    doc.banner_images && doc.banner_images.length > 0 ? true : false;

  return (
    <Layout>
      <Header
        isFront={doc.is_homepage}
        image={doc.banner_image}
        multibanner={showMultiBannerImages}
        multiimages={doc.banner_images}
      />
      <Box component="main">
        {doc.body && <ResolveSlices data={doc.body} />}
      </Box>
      <Footer />
    </Layout>
  );
}

export default Page;

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query PageQuery($id: String!) {
    prismicPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        is_homepage
        banner_image {
          url
          alt
        }
        banner_images {
          image {
            alt
            url
          }
        }
        body {
          ... on PrismicPageDataBodyStatistics {
            slice_type
            items {
              statistic_extension
              statistic_footer_text
              statistic_value
            }
          }
          ... on PrismicPageDataBodyInstagram {
            slice_type
            primary {
              spacer_bottom
              spacer_top
              display_how_many_posts
            }
          }
          ... on PrismicPageDataBodyThumbnailGridImages {
            slice_type
            primary {
              column_space
              show_gradient_overlays_on_images
              text_position
              spacer_bottom
              spacer_top
              title {
                html
                raw
                text
              }
            }
            items {
              image {
                alt
                url
              }
              link {
                uid
                type
              }
              text {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyFullWidthImage {
            slice_type
            primary {
              align_text
              image {
                url
                alt
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyInlineImages {
            slice_type
            primary {
              spacer_bottom
              spacer_top
            }
            items {
              image {
                alt
                url
              }
            }
          }
          ... on PrismicPageDataBodyTextBlock {
            slice_type
            primary {
              add_block_background_color
              align
              spacer_bottom
              spacer_top
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyTextImage {
            slice_type
            primary {
              title {
                html
                raw
                text
              }
              add_block_background_color
              vertical_alignment
              spacer_top
              spacer_bottom
              image_alignment
              image {
                url
                alt
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyListingBlock {
            id
            slice_type
            primary {
              spacer_bottom
              spacer_top
              display_no_current_jobs_message
              title {
                html
                raw
                text
              }
            }
            items {
              link {
                uid
                type
                raw
              }
              list_item_text {
                text
                raw
                html
              }
            }
          }
          ... on PrismicPageDataBodyJobDescription {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              job_title
              job_location
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodySiteOperativeDatabaseForm {
            slice_type
            primary {
              activate_form
              spacer_bottom
              spacer_top
            }
          }
          ... on PrismicPageDataBodyReportIssueForm {
            slice_type
            primary {
              title {
                text
              }
            }
          }
          ... on PrismicPageDataBodyImageCarousel {
            slice_type
            primary {
              spacer_bottom
              spacer_top
            }
            items {
              image {
                url
                alt
              }
              title {
                text
              }
              team_member_name {
                text
              }
              job_title {
                text
              }
              quote {
                html
                raw
                text
              }
            }
          }
        }
      }
    }
  }
`;
