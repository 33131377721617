import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Delete as DeleteIcon } from '@mui/icons-material';

import { ContainerDefault } from 'components/containers';

const reportEmails = {
  'Sexual Harassment': 'rm-hr@rmcontractors.co.uk',
  'Third-Party Harassment': 'rm-hr@rmcontractors.co.uk',
  'Safeguarding Concerns': 'rm-hr@rmcontractors.co.uk',
  Whistleblowing: 'rm-hr@rmcontractors.co.uk',
};

const getReadableValue = key => {
  const readableValues = {
    reportType: 'Report Type',
    incidentDate: 'Incident Date',
    incidentTime: 'Incident Time',
    incidentLocation: 'Incident Location',
    incidentDescription: 'Incident Description',
    personallyWitnessed: 'Personally Witnessed',
    directedTowardsYou: 'Directed Towards You',
    otherWitnesses: 'Other Witnesses',
    witnessNames: 'Witness Names',
    perpetratorName: 'Perpetrator Name',
    perpetratorRole: 'Perpetrator Role',
    perpetratorRelation: 'Perpetrator Relation',
    reportedBefore: 'Reported Before',
    previousReportDetails: 'Previous Report Details',
    evidence: 'Supporting Evidence',
    desiredOutcome: 'Desired Outcome',
    reporterName: 'Reporter Name',
    reporterRole: 'Reporter Role',
    phone: 'Phone Number',
    email: 'Email Address',
    contactMethod: 'Preferred Contact Method',
    anonymous: 'Remain Anonymous',
  };

  return readableValues[key] || key; // Return key as fallback if not found
};

const validationSchema = yup.object({
  reportType: yup.string().required('Report type is required'),
  incidentDate: yup.string().required('Date is required'),
  incidentTime: yup.string().required('Time is required'),
  incidentLocation: yup.string().required('Location is required'),
  incidentDescription: yup.string().required('Description is required'),
  personallyWitnessed: yup.string().required('This field is required'),
  directedTowardsYou: yup.string().required('This field is required'),
  otherWitnesses: yup.string().required('This field is required'),
  witnessNames: yup.string(),
  perpetratorName: yup.string(),
  perpetratorRole: yup.string(),
  perpetratorRelation: yup.string(),
  reportedBefore: yup.string().required('This field is required'),
  previousReportDetails: yup.string(),
  evidence: yup.mixed(),
  desiredOutcome: yup.string().required('This field is required'),
  reporterName: yup.string().when('anonymous', {
    is: false,
    then: schema => schema.required('Name is required'),
  }),
  reporterRole: yup.string().when('anonymous', {
    is: false,
    then: schema => schema.required('Reporter role is required'),
  }),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, 'Phone must be a number')
    .when('anonymous', {
      is: false,
      then: schema => schema.required('Phone number is required'),
    }),
  email: yup
    .string()
    .email('Invalid email')
    .when('anonymous', {
      is: false,
      then: schema => schema.required('Email is required'),
    }),
  contactMethod: yup.string().when('anonymous', {
    is: false,
    then: schema => schema.required('Contact method is required'),
  }),
  anonymous: yup.boolean(),
});

const ReportForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const formik = useFormik({
    initialValues: {
      reportType: '',
      incidentDate: '',
      incidentTime: '',
      incidentLocation: '',
      incidentDescription: '',
      personallyWitnessed: '',
      directedTowardsYou: '',
      otherWitnesses: '',
      witnessNames: '',
      perpetratorName: '',
      perpetratorRole: '',
      perpetratorRelation: '',
      reportedBefore: '',
      previousReportDetails: '',
      evidence: null,
      desiredOutcome: '',
      reporterName: '',
      reporterRole: '',
      phone: '',
      email: '',
      contactMethod: '',
      anonymous: false,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setSubmitting(true);

      const emailToSend = reportEmails[values.reportType];
      const formData = new FormData();
      // Append all form fields
      Object.keys(values).forEach(key => {
        if (key === 'evidence' && Array.isArray(values.evidence)) {
          // Append multiple files
          values.evidence.forEach((file, index) => {
            formData.append(`evidence_${index}`, file); // Use unique names per file
          });
        } else {
          const readableValue = getReadableValue(key);
          formData.append(readableValue, values[key]);
        }
      });
      formData.append('recipient', emailToSend);

      try {
        await axios.post('https://formspree.io/f/xpwqlvlp', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        resetForm();
        setSubmissionStatus({
          success: true,
          message: 'Report submitted successfully!',
        });
      } catch (error) {
        setSubmissionStatus({
          success: false,
          message: 'Submission failed. Please try again.',
        });
      }
      setSubmitting(false);
    },
  });

  const onDrop = acceptedFiles => {
    formik.setFieldValue('evidence', [
      ...(formik.values.evidence || []),
      ...acceptedFiles,
    ]);
  };

  const removeFile = index => {
    const updatedFiles = formik.values.evidence.filter((_, i) => i !== index);
    formik.setFieldValue('evidence', updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: 'image/*,application/pdf,.doc,.docx,.txt',
  });

  return (
    <ContainerDefault maxWidth="lg">
      <div
        style={{
          backgroundColor: '#f2f2f2',
          padding: '4rem',
          borderRadius: '4rem',
          marginBottom: '2rem',
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '2rem' }}>
          Incident Report Form
        </Typography>

        {/* Report Type */}
        <FormControl
          fullWidth
          margin="normal"
          error={formik.touched.reportType && Boolean(formik.errors.reportType)}
        >
          <InputLabel>Select the type of report</InputLabel>
          <Select
            name="reportType"
            value={formik.values.reportType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {Object.keys(reportEmails).map(type => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.reportType && formik.errors.reportType}
          </FormHelperText>
        </FormControl>
      </div>

      {/* Details of Incident */}
      <div
        style={{
          backgroundColor: '#f2f2f2',
          padding: '4rem',
          borderRadius: '4rem',
          marginBottom: '4rem',
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '2rem' }}>
          Details of Incident
        </Typography>

        {[
          { name: 'incidentDate', label: 'Date of incident', type: 'date' },
          { name: 'incidentTime', label: 'Time of incident', type: 'time' },
          {
            name: 'incidentLocation',
            label: 'Location of incident',
            type: 'text',
          },
          {
            name: 'incidentDescription',
            label: 'Description of incident',
            type: 'text',
            multiline: true,
            rows: 4,
          },
        ].map(field => (
          <TextField
            key={field.name}
            label={field.label}
            type={field.type}
            name={field.name}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            multiline={field.multiline || false}
            rows={field.rows || 1}
            value={formik.values[field.name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[field.name] && Boolean(formik.errors[field.name])
            }
            helperText={formik.touched[field.name] && formik.errors[field.name]}
          />
        ))}

        <FormControl fullWidth margin="normal">
          <InputLabel
            sx={{
              '&.Mui-focused, &.MuiInputLabel-shrink': {
                backgroundColor: '#f2f2f2',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              },
            }}
          >
            Did you personally witness the incident?
          </InputLabel>
          <Select
            name="personallyWitnessed"
            value={formik.values.personallyWitnessed}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personallyWitnessed &&
              Boolean(formik.errors.personallyWitnessed)
            }
            helperText={
              formik.touched.personallyWitnessed &&
              formik.errors.personallyWitnessed
            }
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel
            sx={{
              '&.Mui-focused, &.MuiInputLabel-shrink': {
                backgroundColor: '#f2f2f2',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              },
            }}
          >
            Was the incident directed toward you?
          </InputLabel>
          <Select
            name="directedTowardsYou"
            value={formik.values.directedTowardsYou}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.directedTowardsYou &&
              Boolean(formik.errors.directedTowardsYou)
            }
            helperText={
              formik.touched.directedTowardsYou &&
              formik.errors.directedTowardsYou
            }
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel
            sx={{
              '&.Mui-focused, &.MuiInputLabel-shrink': {
                backgroundColor: '#f2f2f2',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              },
            }}
          >
            Were there any other witnesses to the incident?
          </InputLabel>
          <Select
            name="otherWitnesses"
            value={formik.values.otherWitnesses}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.otherWitnesses &&
              Boolean(formik.errors.otherWitnesses)
            }
            helperText={
              formik.touched.otherWitnesses && formik.errors.otherWitnesses
            }
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        {formik.values.otherWitnesses === 'Yes' && (
          <TextField
            label="Witness Names"
            name="witnessNames"
            fullWidth
            margin="normal"
            value={formik.values.witnessNames}
            onChange={formik.handleChange}
          />
        )}
      </div>

      <div
        style={{
          backgroundColor: '#f2f2f2',
          padding: '4rem',
          borderRadius: '4rem',
          marginBottom: '4rem',
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '2rem' }}>
          Alleged Perpetrator/s
        </Typography>
        <TextField
          label="Name(s) of alleged perpetrator(s) (if known)"
          name="perpetratorName"
          fullWidth
          margin="normal"
          value={formik.values.perpetratorName}
          onChange={formik.handleChange}
        />
        <TextField
          label="Role/Position (if known)"
          name="perpetratorRole"
          fullWidth
          margin="normal"
          value={formik.values.perpetratorRole}
          onChange={formik.handleChange}
        />
        <TextField
          label="Relationship to you"
          name="perpetratorRelation"
          fullWidth
          margin="normal"
          value={formik.values.perpetratorRelation}
          onChange={formik.handleChange}
        />
      </div>

      <div
        style={{
          backgroundColor: '#f2f2f2',
          padding: '4rem',
          borderRadius: '4rem',
          marginBottom: '4rem',
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '2rem' }}>
          Reporting History
        </Typography>
        <TextField
          label="Have you reported this to anyone else?"
          name="reportedBefore"
          fullWidth
          margin="normal"
          value={formik.values.reportedBefore}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.reportedBefore &&
            Boolean(formik.errors.reportedBefore)
          }
          helperText={
            formik.touched.reportedBefore && formik.errors.reportedBefore
          }
        />
        <TextField
          label="If yes please provide details"
          name="previousReportDetails"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={formik.values.previousReportDetails}
          onChange={formik.handleChange}
          helperText="Person/Entity reported to, Date of previous report, Outcome action taken"
        />
      </div>

      {/* Supporting Evidence */}
      <div
        style={{
          backgroundColor: '#f2f2f2',
          padding: '4rem',
          borderRadius: '4rem',
          marginBottom: '4rem',
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '2rem' }}>
          Supporting Evidence
        </Typography>
        <p>
          If applicable, please attach any evidence, such as emails, text
          messages, photos, or other documentation.
        </p>

        {/* Drag & Drop Zone */}
        <div
          {...getRootProps()}
          style={{
            border: '2px dashed #ccc',
            padding: '20px',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: '#fafafa',
          }}
        >
          <input {...getInputProps()} name="evidence" />
          <p>Drag & drop files here, or click to select</p>
        </div>

        {/* Display Selected Files */}
        {formik.values.evidence && formik.values.evidence.length > 0 && (
          <div style={{ marginTop: '1rem' }}>
            <Typography variant="h6">Uploaded Files:</Typography>
            <ul>
              {formik.values.evidence.map((file, index) => (
                <li
                  key={index}
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  {file.name} ({(file.size / 1024).toFixed(2)} KB)
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => removeFile(index)}
                    aria-label="Remove file"
                  >
                    <DeleteIcon />
                  </IconButton>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Validation Error Message */}
        {formik.touched.evidence && formik.errors.evidence && (
          <FormHelperText error>{formik.errors.evidence}</FormHelperText>
        )}
      </div>

      <div
        style={{
          backgroundColor: '#f2f2f2',
          padding: '4rem',
          borderRadius: '4rem',
          marginBottom: '4rem',
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '2rem' }}>
          Desired Outcome
        </Typography>
        <TextField
          label="What actions or resolutions would you like to see?"
          name="desiredOutcome"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={formik.values.desiredOutcome}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.desiredOutcome &&
            Boolean(formik.errors.desiredOutcome)
          }
          helperText={
            formik.touched.desiredOutcome && formik.errors.desiredOutcome
          }
        />
      </div>

      {/* Personal Information */}
      <div
        style={{
          backgroundColor: '#f2f2f2',
          padding: '4rem',
          borderRadius: '4rem',
          marginBottom: '4rem',
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '2rem' }}>
          Personal Information
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              name="anonymous"
              onChange={formik.handleChange}
              checked={formik.values.anonymous}
            />
          }
          label="Remain Anonymous"
        />

        {!formik.values.anonymous && (
          <>
            <TextField
              label="Name of reporter"
              name="reporterName"
              fullWidth
              margin="normal"
              value={formik.values.reporterName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.reporterName &&
                Boolean(formik.errors.reporterName)
              }
              helperText={
                formik.touched.reporterName && formik.errors.reporterName
              }
            />
            <TextField
              label="Role/Position"
              name="reporterRole"
              fullWidth
              margin="normal"
              value={formik.values.reporterRole}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.reporterRole &&
                Boolean(formik.errors.reporterRole)
              }
              helperText={
                formik.touched.reporterRole && formik.errors.reporterRole
              }
            />
            <TextField
              label="Phone"
              name="phone"
              fullWidth
              margin="normal"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
              label="Email"
              name="email"
              fullWidth
              margin="normal"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel
                sx={{
                  '&.Mui-focused, &.MuiInputLabel-shrink': {
                    backgroundColor: '#f2f2f2',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                  },
                }}
              >
                Preferred method of communication
              </InputLabel>
              <Select
                name="contactMethod"
                value={formik.values.contactMethod}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.contactMethod &&
                  Boolean(formik.errors.contactMethod)
                }
                helperText={
                  formik.touched.contactMethod && formik.errors.contactMethod
                }
              >
                <MenuItem value="Phone">Phone</MenuItem>
                <MenuItem value="Email">Email</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
      </div>

      {/* Submit Button */}
      <div
        style={{
          marginTop: '4rem',
          marginBottom: '4rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting}
          onClick={() => {
            console.log('Validation errors:', formik.errors);
            formik.handleSubmit();
          }}
        >
          {submitting ? 'Submitting...' : 'Submit Report'}
        </Button>
      </div>
      <div>
        {submissionStatus && (
          <div
            style={{
              padding: '1rem',
              borderRadius: '0.5rem',
              backgroundColor: submissionStatus.success ? '#d4edda' : '#f8d7da',
              color: submissionStatus.success ? '#155724' : '#721c24',
              textAlign: 'center',
              marginBottom: '1rem',
            }}
          >
            {submissionStatus.message}
          </div>
        )}
      </div>
    </ContainerDefault>
  );
};

export default ReportForm;
